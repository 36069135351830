import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import CenterContentHome from "../../components/common/CenterContentHeader";

const topImage = require("../../assets/automate/home/automate_img_1.png");
export const icon1 = require("../../assets/images/trigger/trigger_sec_1_icon.png");
export const icon2 = require("../../assets/images/trigger/trigger_sec_2_icon.png");
export const icon3 = require("../../assets/images/trigger/trigger_sec_3_icon.png");

const section_one = require("../../assets/automate/trigger/trigger_sec_1.png");
const section_two = require("../../assets/automate/trigger/trigger_sec_2.png");
const section_three = require("../../assets/automate/trigger/trigger_sec_3.png");

const featureContent = [
  {
    icon: icon1,
    header: "Event Trigger",
    content:
      "Set up event triggers to auto-respond to app-related events instantly. For various service based, lifecycle based or other critical events, you never miss a beat to take quick actions via workflow automations.",
    url: null,
    link: null,
    image: section_one,
  },
  {
    icon: icon2,
    header: "Polling Trigger",
    content:
      "Remove manual efforts by using polling triggers which can repeatedly check for changes periodically, at regular intervals in third party apps to trigger workflow automations.",
    image: section_two,
    url: null,
    link: null
  },
  {
    icon: icon3,
    header: "Webhook Trigger",
    content:"Make work faster by using webhook triggers that can be set up easily on workativ platform, integrating your existing apps. Call workflows using webhooks from external app or your chatbot easily.",
    image: section_three,
    url: null,
    link: null
  }
];

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Application Triggers to Automate Workflows | Workativ Automate"
        description="Schedule event based triggers, polling triggers or use webhook to call workflows from apps using no-code workflow automation platform. "
        keywords={["polling triggers", "riggers"]}
        ogImage={topImage}
      />
      <Container>
        <section className="workflow_automation_wrapper trigger_page automate_product" >
          <Layout backgroundColor={"bg_header_trigger"} logoFor="AUTOMATE" product="AUTOMATE">
            <RegisterSection
              rightImage={topImage}
              altImage={
                " automate workflows with triggers"
              }
              backgroundColor={"bg_header_trigger"}
            >
             
              <RegisterSection.FormWithContent product="automate" content="">
                <RegisterSection.MainHeader>
                Automate workflows using application triggers
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                Connect your apps and automate workflows via event or polling-based triggers between your favorite apps.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>{" "}
            <div className="automate_triggers_center">
            <CenterContentHome>
            <CenterContentHome.Header>
            What are workflow triggers?
            </CenterContentHome.Header>
            <p>Triggers are conditions to take actions in workflow automatically. You can trigger a workflow based on app related events or by polling the app for any material change.</p>
          </CenterContentHome>
          </div>
            {featureContent.map((feature, index) => {
              if (index % 2 !== 0) {
                return (
                  <div className="support_chatbot">
                    <RightImageWithContentFeature
                      image={feature.image}
                      altImage={feature.header}
                    >
                      <RightImageWithContentFeature.Header>
                        <div className="icon-top-img">
                          <img src={feature.icon} alt="icon" />{" "}
                        </div>
                        <h3>{feature.header}</h3>
                      </RightImageWithContentFeature.Header>

                      <RightImageWithContentFeature.SubHeader>
                        <p
                          dangerouslySetInnerHTML={{ __html: feature.content }}
                        />
                      </RightImageWithContentFeature.SubHeader>
                      <RightImageWithContentFeature.Link>
                        {feature.link && (
                          <div className="card_link_landing">
                            <a href={feature.url} className="url_manipulation">
                              {feature.link}
                              <span className="arrow_svg_link">
                                <ArrowRightICon />
                              </span>
                            </a>
                          </div>
                        )}
                      </RightImageWithContentFeature.Link>
                    </RightImageWithContentFeature>
                  </div>
                );
              }
              return (
                <LeftImageWithContent
                  image={feature.image}
                  altImage={feature.header}
                >
                  <LeftImageWithContent.HeaderIcon>
                    <div className="icon-top-img">
                      <img src={feature.icon} alt="icon" />{" "}
                    </div>
                  </LeftImageWithContent.HeaderIcon>

                  <LeftImageWithContent.MainHeader>
                    {feature.header}
                  </LeftImageWithContent.MainHeader>
                  <LeftImageWithContent.SubHeader>
                    <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                  </LeftImageWithContent.SubHeader>
                  <LeftImageWithContent.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                  </LeftImageWithContent.Link>
                </LeftImageWithContent>
              );
            })}
             <div className="automate_homepage">
          <RequestForm product="automate" content="We need your feedback, inputs, and experience to help us build a great product!" AutomateCTA automateContent autoamteButton automateName isFooterForm={true} />
          </div>
          </Layout>
        </section>
      </Container>
    </>
  );
}
